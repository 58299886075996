import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { graphql } from "gatsby";
import PageLayout from "./../../../templates/service";
export const PageQuery_service_nl_graphicsmdx = graphql`
        query PageQuery_service_nl_graphicsmdx {
            # Required by gatsby-plugin-react-i18next
            locales: allLocale(filter: {language: {eq: "nl"}, ns: {in: ["common", "service"]}}) {
                ...TranslationFragment
            }
            # Required by gatsby-plugin-image
            mdx(frontmatter:
                {
                    path: {regex: "/graphics/"},
                    language: {eq: "nl"}
                }) {
                frontmatter {
                    ...MdxServiceFrontmatterFragment
                }
            }
        }
    `;
export const _frontmatter = {
  "title": "Grafisch",
  "path": "/service/graphics",
  "date": "2022-10-15T00:00:00.000Z",
  "template": "Service",
  "language": "nl",
  "icon": "bx-brush",
  "tags": ["front-end", "ontwikkelaar", "senior"],
  "experience": [{
    "title": "VR Tijdreis",
    "task": "Hoofdontwikkelaar / Visueel",
    "tags": ["ontwikkelaar", "visueel"],
    "outcome": ["Demonstratie Oculus Rift DK1 & DK2", "Omgeving van een oude stad in VR", "Onvergetelijke overgangen met wormgaten", "Stabiele app tijdens gehele evenement"],
    "link": ["https://alkmaarcentraal.nl/7841-unieke-reis-in-tijdmachine-op-alkmaarse-open-monumentendag"],
    "image_public": ["/images/service/graphics/time-vortex.gif"],
    "copyright": null
  }, {
    "title": "Revalidatie In 3D",
    "task": "Lead Unity Graphics",
    "tags": ["lead", "ontwikkelaar", "unity3d"],
    "outcome": ["Kleureffecten dmv LUT", "Interactieve omgevingskenmerken", "Realistische belichting (pre-HDRP)", "Bidirectionele communicatie back-end"],
    "link": null,
    "image": ["./images/mobile/tq-app-client-01.jpg"],
    "copyright": "TweeQ LLC"
  }, {
    "title": "Motion Capture Verwerking",
    "task": "Hoofdontwikkelaar",
    "tags": ["lead", "ontwikkelaar"],
    "outcome": ["Batchverwerking van bronbestanden", "Metadata berekenen + aanpassen skelet", "Efficiënte verwerking van duizenden animaties"],
    "link": null,
    "copyright": null
  }, {
    "title": "Weersimulatie",
    "task": "Hoofdontwikkelaar",
    "tags": ["ontwikkelaar"],
    "outcome": ["Volumetrische wolken", "Weergave van zon, maan en lichtstralen", "Weergave gebaseerd op fysieke eigenschappen", "Rayleigh- en Mie-verstrooiing", "Integratie voor Unity 3D (v4 & 5)"],
    "link": null,
    "image": ["./images/graphics/cb-sky-clouds-01.jpg", "./images/graphics/cb-sky-clouds-02.jpg"],
    "copyright": "Cheesebyte"
  }]
};
const layoutProps = {
  PageQuery_service_nl_graphicsmdx,
  _frontmatter
};
const MDXLayout = PageLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "visueel-werk-en-verwerking-van-mocap",
      "style": {
        "position": "relative"
      }
    }}>{`Visueel werk en verwerking van mocap`}<a parentName="h1" {...{
        "href": "#visueel-werk-en-verwerking-van-mocap",
        "aria-label": "visueel werk en verwerking van mocap permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h1>
    <p>{`Eind jaren negentig schreef ik hobbymatig kleine 3D engines. Aansluitend deed ik professionele ervaring op met het opzetten van "pipelines" om voorgaande te ondersteunen.`}</p>
    <h2 {...{
      "id": "vaardigheden",
      "style": {
        "position": "relative"
      }
    }}>{`Vaardigheden`}<a parentName="h2" {...{
        "href": "#vaardigheden",
        "aria-label": "vaardigheden permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h2>
    <ul>
      <li parentName="ul">{`8+ jaar ervaring met Unity 3D`}</li>
      <li parentName="ul">{`10+ jaar met beeldbewerking- en verwerking`}</li>
      <li parentName="ul">{`12+ jaar met 3D-ontwikkeling in het algemeen`}</li>
      <li parentName="ul">{`Verleden als tech-artiest voor film + `}<a parentName="li" {...{
          "href": "https://nl.wikipedia.org/wiki/Motion_capture"
        }}>{`mocap`}</a></li>
    </ul>
    <h2 {...{
      "id": "technisch",
      "style": {
        "position": "relative"
      }
    }}>{`Technisch`}<a parentName="h2" {...{
        "href": "#technisch",
        "aria-label": "technisch permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h2>
    <ul>
      <li parentName="ul">{`Direct3D, OpenGL (Vulkan: nog niet)`}</li>
      <li parentName="ul">{`Wiskunde (lineaire algebra, geo) + basisfysica`}</li>
      <li parentName="ul">{`Acceleratiestructuren ter verbetering van prestatie`}</li>
      <li parentName="ul">{`Computervisie + ML (OpenCV, Tensorflow, ML.NET)`}</li>
    </ul>





    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      